<template>
<div id="landing-page">
  <img class="bg-img" src="../assets/bg_1.png" alt="">
  <img class="bg-img bottom" src="../assets/bg_1.png" alt="">

  <div class="main" v-if="!loading || clicked" :class="{fade}">
    <button v-if="!clicked" class="main-btn" @click="shouldIHaveWine()">
      <span class="should">Should I have wine?</span>
    </button>

    <div v-else class="result">

      <span :class="['result-title', is420 ? 'blazeIt' : '' ]">
        <img  v-if="questionCnt === 0 && is420" class="weed" src="../assets/weed.png" alt="">
        {{ results[questionCnt] }}
        <img  v-if="questionCnt === 0 && is420" class="weed" src="../assets/weed.png" alt="">
      </span>
        

      <button v-if="questionCnt === 0 && is420" class="second-question" @click="indicaOrSativa()">
        Indica or sativa...?
      </button>

      <button v-else-if="questionCnt === 0 && !isAnswerNo" class="second-question" @click="redOrWhite()">
        Red or white...?
      </button>

      <button v-if="questionCnt === 0 && isAnswerNo" class="second-question" @click="ginOrWhiteclaw()">
        Alright, then gin or white claw?
      </button>

      <button v-if="questionCnt === 1 && !isAnswerNo && !is420" class="second-question" @click="whatKind()">
        Ok but what kind?
      </button>
    </div>
  </div>

  <div v-else class="loading">
    <img src="../assets/winebottle_1.png" alt="">
  </div>
</div>
</template>

<script>
export default {
  name: 'LandingPage',
  data () {
    return {
      clicked: false,
      shouldI: null,
      loading: false,
      fade: false,
      questionCnt: -1,
      extraQuestions: ['Red or white...'],
      results: [],
      isAnswerNo: false,
      is420: false
    }
  },
  methods: {
    shouldIHaveWine() {
      const date = new Date();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      this.is420 = minutes === 20 && (hours === 4 || hours === 16);

      this.fade = true;
      setTimeout(() => {
        this.loading = true;
      }, 500)

      const random = Math.floor(Math.random() * 100);
      if (this.is420) {
        this.results.push('420 blaze it');
      }
      else if (random > 0 && random < 51) {
        this.results.push('Yes!!');
      } else if (random > 0) {
        this.results.push('No :(')
        this.isAnswerNo = true;
      } else {
        this.results.push('Nuria, you\'re an alcoholic')
        this.isAnswerNo = true;
      }

      setTimeout(() => {
        this.loading = false
        this.clicked = true;
        this.fade = false;
        this.questionCnt += 1;
      }, 5000)
    },
    redOrWhite(){
      const random = Math.floor(Math.random() * 2);
      const colors = ['White!', 'Red!']
      this.results.push(colors[random])
      this.questionCnt += 1;
    },
    indicaOrSativa(){
      const random = Math.floor(Math.random() * 2);
      const colors = ['Indica!', 'Sativa!']
      this.results.push(colors[random])
      this.questionCnt += 1;
    },
    ginOrWhiteclaw(){
      const random = Math.floor(Math.random() * 2);
      const colors = ['Gin!', 'White claw ;)']
      this.results.push(colors[random])
      this.questionCnt += 1;
    },
    whatKind() {
      const random = Math.floor(Math.random() * 6);
      const whites = ['Chardonnay', 'Pinot Grigio', 'Sauvignon Blanc', 'Riesling', 'Gewürztraminer'];
      const reds = ['Merlot', 'Cabernet Sauvignon', 'Pinot Noir', 'Shiraz', 'Malbec']
      if (this.results[this.questionCnt] === 'White!') {
        this.results.push(whites[random]);
      } else {
        this.results.push(reds[random]);
      }
      this.questionCnt += 1;
    } 
  }
}
</script>

<style lang="scss" scoped>
#landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fbaaae;
  border-radius: 20px;
  overflow: hidden;
  opacity: 1;
  transition: 350ms ease all;
  &.fade {
    opacity: 0;
  }
}
.bg-img {
  width: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
  &.bottom {
    opacity: 0;
  }
}
.main-btn {
  padding: 25px;
  background: #ffe9ea;
 
  transition: 250ms ease all;
  &:hover {
    background: #fbaaae;
  }
}

.should {
  font-size: 3em;
  font-family: 'Parisienne', cursive;
  span {
    color:black !important; 
  }
}
button {
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}

.result {
  background: white;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .result-title {
    font-size: 3em;
    font-family: 'Parisienne', cursive;
    padding: 15px;
    &.blazeIt {
       font-family: 'Patrick Hand', cursive;
    }
  }
  .second-question {
    color: grey;
    text-decoration: underline;
    color: grey;
    padding: 15px 0;
    transition: 250ms ease all;
    &:hover {
      color: #000;
    }
  }
}

.loading {
  
  transform: rotate(360deg);
  transition: 2000ms ease all;
  img {
    width: 100%;
    max-height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75px;
    height: 300px;
    margin: -126px 0 0 -45px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
  }
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
}

.weed {
  width: 50px
}

@media only screen and (max-width: 600px) {
  .should {
    font-size: 2em;
  }
  .bg-img.bottom {
    opacity: 1;
    position: fixed;
    z-index: -1;
    transform: rotate(180deg);
    top: 500px;
  }
}
</style>


